<template>
  <div class="unsubscribe-content">
    <PageHeader header-text="Change Details" />
    <hr><br>
    <validation-observer ref="textInput">
      <CustomTextInput
        v-model="userDataTemp.contact.firstName"
        header="First Name"
        placeholder="First Name"
        class="mb-2"
      />
      <CustomTextInput
        v-model="userDataTemp.contact.lastName"
        header="Last Name"
        placeholder="Last Name"
        class="mb-2"
      />
      <CustomTextInput
        v-model="userDataTemp.emailAddress"
        :email="true"
        header="Primary Email"
        placeholder="Primary Email"
        class="mb-2"
      />
      <CustomTextInput
        v-model="userDataTemp.secondaryEmail"
        :email="true"
        header="Secondary Email"
        placeholder="Secondary Email"
        class="mb-2"
      />
    </validation-observer>
    <slot />
    <v-btn
      block
      height="34px"
      class=" custom-button custom-button--blue"
      @click="save"
    >
      Save
    </v-btn>
    <div v-if="hasHistory" class="d-flex justify-center pt-4">
      <div
        class="simple-text-button button--blue"
        @click="$emit('goto-unsubscribe')"
      >
        Cancel
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";

export default {
  name: "ChangeDetails",
  components: {
    PageHeader,
    CustomTextInput,
  },
  props: {
    userData: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      userDataTemp: {},
    };
  },
  computed: {
  },
  async created() {
    this.userDataTemp = { ...this.userData }
  },
  methods: {
    async validate() {
      return await this.$refs.textInput.validate();
    },
    hasHistory(){
      return window.history.length > 0;
    },
    reset() {
      this.$refs.textInput.reset();
    },
    resetValidation() {
      this.$refs.textInput.resetValidation();
    },
    async save() {
      try {
        if (await this.validate()) {
          this.$emit('save', this.userDataTemp);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unsubscribe-content {
  background: #fff;
  padding: 44px 46px 60px;
  width: 100%;
  max-width: 575px;
  font-family: "Open Sans", sans-serif;
}
</style>
