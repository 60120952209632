<template>
  <v-container
    fluid
    class="unsubscribe-background"
  >
    <v-container
      class="unsubscribe-container"
    >
      <div
        v-if="personalizationSettings.logoUrl !== null && personalizationSettings.logoUrl !== undefined"
        class="unsubscribe-logo-container"
      >
        <img
          :src="personalizationSettings.logoUrl"
          alt="Personalization Setting logo"
          class="unsubscribe-logo-img"
        >
      </div>
      <div
        v-if="userData.primaryEmail || userData.emailAddress"
        class="unsubscribe-content-container"
      >
        <Unsubscribe
          v-if="viewMode === 'unsubscribe'"
          :email-address="userData.primaryEmail || userData.emailAddress"
          :personalization-settings="personalizationSettings"
          @unsubscribe="unsubscribe($event)"
          @change-details="goToChangeDetailsView"
        >
          <v-alert
            v-if="showAlert"
            dense
            outlined
            text
            type="success"
          >
            {{ successMessage }}
          </v-alert>
        </Unsubscribe>
        <ChangeDetails
          v-else
          :user-data="userData"
          @save="saveChangedDetails($event)"
          @goto-unsubscribe="goToUnsubscribe($event)"
        >
          <v-alert
            v-if="showAlert"
            dense
            outlined
            text
            type="success"
          >
            Thank you! You may close this window.
          </v-alert>
        </ChangeDetails>
      </div>
      <div
        v-if="personalizationSettings.footerText !== null && personalizationSettings.footerText !== undefined"
        class="unsubscribe-footer-text"
      >
        <div
          v-for="(footerTextBlock, i) in footerText"
          :key="'footer-text-block-' + i"
        >
          {{ footerTextBlock }}
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import Unsubscribe from "@/views/Unsubscribe/components/Unsubscribe";
import ChangeDetails from "@/views/Unsubscribe/components/ChangeDetails";

export default {
  name: "UnsubscribeView",
  components: {
    Unsubscribe,
    ChangeDetails
  },
  props: {
    accountId: {
      default: () => ''
    },
    campaignTrackingToken: {
      default: () => ''
    },
    emailToken: {
      default: () => ''
    },
  },
  data() {
    return {
      viewMode: "unsubscribe",
      showAlert: false,
      alertColor: 'success',
      userData: {},
      personalizationSettings: {},
    };
  },
  computed: {
    successMessage() {
      if (this.personalizationSettings.type === 'segment') {
        return 'Thank you. We have received your preferences or unsubscribe request(s).';
      }

      return 'Thank you. We have received your unsubscribe request.';
    },
    footerText() {
      if (this.personalizationSettings.footerText === null || this.personalizationSettings.footerText === undefined) {
        return [];
      }

      return this.personalizationSettings.footerText.split("\n");
    },
  },
  async created() {
    await this.getPersonalizationSettings();
    if (this.$route.name === 'ChangeUnsubscribeDetails'){
      this.goToChangeDetailsView();
    }
  },
  methods: {
    async getPersonalizationSettings() {
      const result = (
        await this.$rest.unsubscribe.email_page_settings(this.emailToken, {
          headers: {
            'X-Account': this.accountId,
          },
        })
      ).data;

      this.personalizationSettings = result.personalizationSettings;
      this.userData = result.contactEmail;
    },
    async unsubscribe(optOutDetails) {
      this.showAlert = true;

      await this.$rest.unsubscribe.post_resource({
        campaignTrackingToken: this.campaignTrackingToken,
        emailToken: this.emailToken,
        isFullOptOut: optOutDetails.isFullOptOut,
        segments: optOutDetails.segments,
        optOutReason: optOutDetails.reason,
        optOutDate: new Date(Date.now())
      }, {
        headers: {
          'X-Account': this.accountId,
        },
      });

      this.showAlert = true;
    },
    goToChangeDetailsView() {
      this.viewMode = 'change_details';

      this.$router.push({name: 'ChangeUnsubscribeDetails', params: {
        accountId: this.accountId,
        campaignTrackingToken: this.campaignTrackingToken,
        emailToken: this.emailToken,
      }});
    },
    goToUnsubscribe() {
      this.viewMode = 'unsubscribe';

      this.$router.push({
        name: 'Unsubscribe', params: {
          accountId: this.accountId,
          campaignTrackingToken: this.campaignTrackingToken,
          emailToken: this.emailToken,
        }
      });
    },
    async saveChangedDetails(data) {
      this.userData = { ...data }

      await this.$rest.unsubscribe.change_details({
        campaignTrackingToken: this.campaignTrackingToken,
        emailToken: this.emailToken,
        firstName: this.userData?.contact?.firstName || '',
        lastName: this.userData?.contact?.lastName || '',
        primaryEmail: this.userData?.emailAddress || this.userData?.primaryEmail || '',
        secondaryEmail: this.userData.secondaryEmail || null
      }, {
        headers: {
          'X-Account': this.accountId,
        },
      });

      this.showAlert = true;
    },
  },
};
</script>
